import React, {useState} from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import Lightbox from "react-image-lightbox"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "reactstrap"
import "react-image-lightbox/style.css"

import imgManufactoryIcon1 from "../images/manufactory-icon1.png"
import imgManufactoryIcon2 from "../images/manufactory-icon2.png"
import imgManufactoryIcon3 from "../images/manufactory-icon3.png"
import imgManufactoryIcon4 from "../images/manufactory-icon4.png"
import imgManufactoryIcon5 from "../images/manufactory-icon5.png"

const ProjectsPage = () => {
  const [photoIndex, setPhotoIndex ] = useState(0)
  const [isOpen, setIsOpen ] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { absolutePath: { regex: "/images/manufactory\//" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                aspectRatio
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      homeManufactoryBg: file(relativePath: { eq: "home-manufactory-bg.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const images = data.allFile.edges

  const sizes = [
    {gridColumn: "1 / span 20", gridRow: "1 / 1"},          // row: 1
    {gridColumn: "1 / span 8", gridRow: "2 / span 2"},      // row: 2
    {gridColumn: "9 / span 12", gridRow: "2 / 2"},
    {gridColumn: "9 / span 12", gridRow: "3 / 3"},
    {gridColumn: "1 / span 20", gridRow: "4 / 4"},          // row: 3
    {gridColumn: "1 / span 8", gridRow: "5 / 5"},
    {gridColumn: "9 / span 6", gridRow: "5 / 5"},
    {gridColumn: "15 / span 6", gridRow: "5 / 5"},
    {gridColumn: "1 / span 8", gridRow: "6 / span 2"},
    {gridColumn: "9 / span 6", gridRow: "6 / 6"},
    {gridColumn: "15 / span 6", gridRow: "6 / 6"},
    {gridColumn: "9 / span 12", gridRow: "7 / 7"},
    {gridColumn: "1 / span 8", gridRow: "8 / 8"},
    {gridColumn: "9 / span 12", gridRow: "8 / span 2"},
    {gridColumn: "1 / span 8", gridRow: "9 / 9"},
    {gridColumn: "1 / span 8", gridRow: "10 / span 2"},
    {gridColumn: "9 / span 12", gridRow: "10 / 10"},
    {gridColumn: "9 / span 12", gridRow: "11 / 11"},
    {gridColumn: "1 / span 8", gridRow: "12 / 12"},
    {gridColumn: "9 / span 12", gridRow: "12 / span 2"},
    {gridColumn: "1 / span 8", gridRow: "13 / 13"},
    {gridColumn: "1 / span 8", gridRow: "14 / 14"},
    {gridColumn: "9 / span 12", gridRow: "14 / 14"},
    {gridColumn: "1 / span 8", gridRow: "15 / 15"},
    {gridColumn: "9 / span 6", gridRow: "15 / 15"},
    {gridColumn: "15 / span 6", gridRow: "15 / 15"},
    {gridColumn: "1 / span 8", gridRow: "16 / span 2"},
    {gridColumn: "9 / span 6", gridRow: "16 / 16"},
    {gridColumn: "15 / span 6", gridRow: "16 / 16"},
    {gridColumn: "9 / span 12", gridRow: "17 / 17"},
    {gridColumn: "1 / span 20", gridRow: "18 / 18"},
    {gridColumn: "1 / span 8", gridRow: "19 / 19"},
    {gridColumn: "9 / span 12", gridRow: "19 / 19"},
  ]

  return (
    <Layout className="subpage">
      <SEO
        title="Fabryka"
        description="Początkowo pracowaliśmy w oparciu o dostawców różnych rozwiązań, jednak z czasem rozwinęliśmy nasz park maszynowy do takiego rozmiaru, że obecnie wszystko wykonujemy u siebie."
      />
      <BackgroundImage fluid={data.homeManufactoryBg.childImageSharp.fluid}>
      <section className="home-manufactory">
        <Container>
          <h2>Fabryka</h2>
          <p>Tu powstają wszystkie nasze realizacje - serie, które produkujemy od lat, zestawy mebli na wymiar oraz jednorazowe produkty.</p>
        </Container>
      </section>
      </BackgroundImage>
      <section className="black-bg">
        <Container className="home-services home-manufactory-services">
          <div className="service-card">
            <img src={imgManufactoryIcon1} alt="Produkcja mebli" />
            <div className="header">Produkcja<br/>mebli</div>
            <div className="body">Projektowanie, produkcja, montaż mebli na wymiar i wolnostojących.</div>
          </div>
          <div className="service-card">
            <img src={imgManufactoryIcon2} alt="Obróbka płyt meblowych" />
            <div className="header">Obróbka płyt meblowych</div>
            <div className="body">Formatowanie CNC, wiercenie i frezowanie CNC, cięcię kątowe, lakierowanie.</div>
          </div>
          <div className="service-card">
            <img src={imgManufactoryIcon3} alt="Obróbka metalu" />
            <div className="header">Obróbka<br/>metalu</div>
            <div className="body">Cięcie na laserze, frezowanie, walcowanie blach i profili, gięcie, spawanie stali i aluminium, lakierowanie proszkowe.</div>
          </div>
          <div className="service-card">
            <img src={imgManufactoryIcon4} alt="Lakierowanie" />
            <div className="header">Lakierowanie<br/>&nbsp;</div>
            <div className="body">Wszystkich powierzchni proszkowo lub natryskowo.</div>
          </div>
          <div className="service-card">
            <img src={imgManufactoryIcon5} alt="Produkcja reklam świetlnych" />
            <div className="header">Produkcja reklam świetlnych</div>
            <div className="body">Kasetony, szyldy, litery blokowe, tablice reklamowe standy promocyjne, a także inne, indywidualne formy reklamowe.</div>
          </div>
        </Container>
      </section>
      <section className="black-bg manufactory-images">
        <Container>
          <div className="masonry">
            {images.map((item, key) => {
              return (
                <div style={sizes[key]} onClick={() => {setIsOpen(true); setPhotoIndex(key)}} className="pointer">
                  <Img fluid={item.node.childImageSharp.fluid} key={key} />
                </div>
              )
            })}
          </div>
        </Container>
      </section>
      {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].node.childImageSharp.fluid.src}
            nextSrc={
              images[(photoIndex + 1) % images.length].node.childImageSharp
                .fluid.src
            }
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].node
                .childImageSharp.fluid.src
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
    </Layout>
  )
}

export default ProjectsPage
